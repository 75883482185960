import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import NavLink, { NavLinkProps } from 'routes/components/Shared/Link/NavLink'

import classes from './ArticleSidebar.module.css'

export function ArticleSidebarHeader({ children }) {
  return <h3 className={clsx('likeH4', classes.sidebarHeader)}>{children}</h3>
}

export function ArticleSidebarLinks({ children }) {
  return <ul className={classes.sidebarLinks}>{children}</ul>
}

export function ArticleSidebarLink({
  children,
  href,
}: PropsWithChildren<{ href: NavLinkProps['href'] }>) {
  return (
    <li className={classes.sidebarLink}>
      <NavLink href={href}>{children}</NavLink>
    </li>
  )
}
