import { Link } from 'routes/components/Shared'
import { G2_INTEGRATION_APP_URL } from 'routes/constants'
import { ReviewsDataType } from './reviewsData'
import clsx from 'clsx'
import classes from './Reviews.module.scss'

export const ReviewsCard = ({ card }: { card: ReviewsDataType }) => {
  const { name, photo, position, company, content, integrationLink, g2Link } =
    card

  const reviewLink = g2Link
    ? `${G2_INTEGRATION_APP_URL}${g2Link}`
    : integrationLink

  return (
    <div className={clsx('flex-column', classes.card)}>
      <div className={classes.card_top}>
        <p
          className={classes.card_review}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>

      <div className={clsx('flex-column', classes.card_bottom)}>
        <div className={clsx('flex-column', classes.card_author)}>
          <div className={classes.card_wrapper}>
            <div className={clsx('page__glow', classes.card_glow)} />
            <div className={classes.card_visual}>
              <div className={classes.card_image}>
                <img src={photo.src} alt={name} />
              </div>
            </div>
          </div>
          <div className={classes.card_info}>
            <h3 className={classes.card_name}>{name}</h3>
            {position && (
              <p className={classes.card_caption}>
                {position} at {company}
              </p>
            )}
          </div>
        </div>

        <div className={classes.card_actions}>
          <Link
            className={clsx('page__button', classes.card_link)}
            type='secondary'
            target='_blank'
            href={reviewLink}
          >
            <span>Read public review</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              className={classes.card_icon}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.2'
                d='M17 7 7 17M17 7H8m9 0v9'
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  )
}
