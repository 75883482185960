import { TableBodyDataType } from './tableData'
import IconCheck from './icon-check.svg'
import IconX from './icon-x.svg'
import clsx from 'clsx'
import classes from './ChecklistTable.module.scss'

export const ChecklistTableBodyRow = ({
  item,
}: {
  item: TableBodyDataType
}) => {
  const { rowNumber, title, product1, product2 } = item

  const iconType = (type: boolean) => {
    return type ? IconCheck : IconX
  }

  return (
    <tr className={clsx(classes.row, classes.row__body)}>
      <th scope='row' className={clsx(classes.cell, classes.cell__number)}>
        {rowNumber}
      </th>
      <td className={clsx(classes.cell, classes.cell__body)}>{title}</td>
      <td className={clsx(classes.cell, classes.cell__body)}>
        <img
          src={iconType(product1).src}
          alt='Icon'
          className={clsx(classes.icon)}
        />
      </td>
      <td className={clsx(classes.cell, classes.cell__body)}>
        <img
          src={iconType(product2).src}
          alt='Icon'
          className={clsx(classes.icon)}
        />
      </td>
    </tr>
  )
}
