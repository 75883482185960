import React, { useState } from 'react'
import clsx from 'clsx'
import { useViewport } from 'hooks'
import { Link, Icon } from 'routes/components/Shared'
import { navigationArray } from 'routes/components/Layout/Header/navigationArray'

export const HeaderNavigation = () => {
  const [subMenuActiveIndex, setSubMenuActiveIndex] = useState(null)
  const { isTablet } = useViewport()

  const toggleSubMenu = (index) => {
    if (isTablet) {
      setSubMenuActiveIndex((prevIndex) => (prevIndex === index ? null : index))
    }
  }

  return (
    <nav className='header__menu'>
      <ul className='flex-column header__menu-list'>
        {navigationArray.map((item, idx) => (
          <NavigationItem
            key={idx}
            item={item}
            index={idx}
            onClick={() => toggleSubMenu(idx)}
            isActive={subMenuActiveIndex === idx}
          />
        ))}
      </ul>
    </nav>
  )
}

const NavigationItem = ({ item, onClick, isActive }) => {
  const { name, href, type, columns } = item

  return (
    <>
      {href ? (
        <li className='header__menu-item'>
          <Link href={href} target='_self' className='header__menu-link'>
            {name}
          </Link>
        </li>
      ) : (
        <li
          className={clsx(
            'header__menu-item',
            'header__menu-item--dropdown',
            isActive && 'header__menu-item--open',
          )}
          onClick={onClick}
        >
          <span className='header__submenu'>
            <Icon type='menu-check' className='header__submenu-icon' />
            {name}
          </span>
          <SubMenu subMenuArray={columns} type={type} />
        </li>
      )}
    </>
  )
}

const SubMenu = ({ subMenuArray, type }) => {
  return (
    <div className={clsx('submenu', `submenu${type}`)}>
      <div className={'submenu__wrapper'}>
        {subMenuArray.map(({ caption, links }, idx) => (
          <div className='submenu__column' key={idx}>
            <div>
              {caption && <span className='submenu__caption'>{caption}</span>}
              <ul className='flex-column submenu__list'>
                {links.map(({ title, href, description }, idx) => (
                  <li className='submenu__item' key={idx}>
                    <Link href={href} target='_self' className='submenu__link'>
                      {title}
                      <Icon type='link-arrow' className='submenu__icon' />
                    </Link>
                    {description && (
                      <p className='submenu__description'>{description}</p>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
