import { AppProps } from 'next/app'
import Head from 'next/head'

import * as Sentry from '@sentry/react'

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { TrackingContextProvider, HeadMetatags } from 'routes/components/Layout'

import 'styles/globals.css'
import 'styles/website.css'
import 'styles-scss/index.scss'

if (process.env.1) {
  Sentry.init({
    dsn: 'https://ab68abc0f6663746bafb1e2b1d82e33c@o956397.ingest.sentry.io/4506388004864000',
  })
}

export default function IntegrationConsoleApp({
  Component,
  pageProps,
}: AppProps) {
  const HeadContent = () => (
    <>
      <Head>
        {process.env.1 && (
          <>
            <script type='text/javascript' src='/tracking.js' />
            <meta
              name='zd-site-verification'
              content='eoedqvh0fv7ibj7r6n0y0r'
            />
            <script
              data-customeros
              dangerouslySetInnerHTML={{
                __html: `
                  ((c, u, s, t, o, m, e, r, O, S) => {
                    var customerOS = document.createElement(s);
                    customerOS.src = u;
                    customerOS.async = true;
                    (document.body || document.head).appendChild(customerOS);
                  })(window, "https://app.customeros.ai/analytics-0.1.js", "script");
                `,
              }}
            />
          </>
        )}
      </Head>
      <HeadMetatags
        title='Integration Infrastructure for SaaS Products - Integration.app'
        description={
          'Integration platforms are too restrictive. Level up your the integration infrastructure of your SaaS product with our AI-powered framework.'
        }
      />
    </>
  )

  return (
    <>
      <TrackingContextProvider>
        <HeadContent />
        {process.env.1 ? (
          <GTMProvider state={{ id: 'GTM-TTWK8G4' }}>
            <Component {...pageProps} />
          </GTMProvider>
        ) : (
          <Component {...pageProps} />
        )}
      </TrackingContextProvider>
    </>
  )
}
