import { Link } from 'routes/components/Shared'
import { LINKS } from 'routes/constants'
import { Banner } from './Banner'
import classes from './Banner.module.scss'

export function AnnouncementBlock() {
  return (
    <Banner>
      <Link
        href={LINKS.CONNECTOR_BUILDER}
        target={'_self'}
        type='noLine'
        className={classes.link}
      >
        ⚡&ensp;Integration.app launched Connector Builder: a new way to build
        integrations using LLM models
      </Link>
    </Banner>
  )
}
