import { StaticImageData } from 'next/image'
import BestSupportBadge from './summer-2024-best-support.svg'
import EasiestAdminBadge from './summer-2024-easiest-admin.svg'
import EasiestSetupBadge from './summer-2024-easiest-setup.svg'
import EasiestBusinessBadge from './summer-2024-easiest-to-do-business.svg'
import HighPerformerBadge from './summer-2024-high-performer.svg'

export type BadgesG2DataType = {
  caption: string
  badge: StaticImageData
}

export const badgesG2Data: BadgesG2DataType[] = [
  {
    caption: 'G2 Spring 2024: Easiest Admin',
    badge: EasiestAdminBadge,
  },
  {
    caption: 'G2 Spring 2024 High Perfomer',
    badge: HighPerformerBadge,
  },
  {
    caption: 'G2 Spring 2024: Easiest to Do Business with',
    badge: EasiestBusinessBadge,
  },
  {
    caption: 'G2 Spring 2024: Best Support',
    badge: BestSupportBadge,
  },
  {
    caption: 'G2 Spring 2024: Easiest Setup',
    badge: EasiestSetupBadge,
  },
]
