import React from 'react'
import { useViewport } from 'hooks'
import { Icon, BookCalendlyButton } from 'routes/components/Shared'
import LoginLink from 'routes/components/Shared/Link/LoginLink'

export const AdvertiseBlock = () => {
  const { isMobile } = useViewport()

  return (
    <div className='advertise-block'>
      <div className='advertise-block__body'>
        <div className='page__background advertise-block__background'></div>

        <div className='flex-column advertise-block__content'>
          <h2 className='page__title advertise-block__title'>
            Get better integration infrastructure for your SaaS product today.
          </h2>

          <div className='page__actions advertise-block__actions'>
            <LoginLink
              className='page__button advertise-block__button'
              type='primary'
            >
              Sign Up
            </LoginLink>

            <BookCalendlyButton
              className='page__button advertise-block__button'
              category='secondary'
              isReversed
            >
              Schedule a Demo
            </BookCalendlyButton>
          </div>
        </div>

        {!isMobile && (
          <Icon
            className='advertise-block__visual'
            type='advertise-visual--1'
          />
        )}
      </div>
    </div>
  )
}
