import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import { Button, LogoBlock, Icon } from 'routes/components/Shared'
import { HeaderMobileMenu } from './HeaderMobileMenu'
import { HeaderNavigation } from './HeaderNavigation'
import { HeaderActions } from './HeaderActions'

export const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const headerRef = useRef(null)

  // to remove classes for mobile menu on <body> and <header>
  // if device width changes to desktop
  useEffect(() => {
    const updateDeviceWidth = (e) => {
      if (!e.matches) return
      setShowMobileMenu(false)
    }

    window
      .matchMedia('(min-width: 992px)')
      .addEventListener('change', updateDeviceWidth)

    return () => {
      window
        .matchMedia('(min-width: 992px)')
        .removeEventListener('change', updateDeviceWidth)
    }
  }, [setShowMobileMenu])

  useEffect(() => {
    document.body.classList.toggle('mobile-open', showMobileMenu)
  }, [showMobileMenu])

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  return (
    <>
      <header
        className={clsx('header', showMobileMenu && 'header--open')}
        ref={headerRef}
      >
        <div className='container'>
          <div className='header__body'>
            <div className='header__block header__block--logo'>
              <Button
                className='btn--menu header__menu-button'
                onClick={toggleMobileMenu}
                aria-label='Menu'
              >
                <Icon type={showMobileMenu ? 'menu-close' : 'menu-button'} />
              </Button>

              <LogoBlock className='header__logo' />
            </div>

            <div className='header__block header__block--navigation'>
              {showMobileMenu && (
                <HeaderMobileMenu
                  headerRef={headerRef}
                />
              )}
              <HeaderNavigation />
            </div>

            <div className='header__block header__block--actions'>
              <HeaderActions />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
