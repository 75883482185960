import React from 'react'
import clsx from 'clsx'
import { LogoBlock, TableCell } from 'routes/components/Shared'

export const TableRowMobile = ({ data, rowKey, isPrimary }) => {
  const dataValue = data.comparison_table[rowKey]

  return (
    <div
      className={clsx(
        'table__block-row',
        'table__block-row--data',
        isPrimary && 'table__block-row--primary',
      )}
    >
      {isPrimary ? (
        <TableCell value={<LogoBlock className='table__logo' />} />
      ) : (
        <TableCell value={data.name} />
      )}

      <TableCell value={dataValue} />
    </div>
  )
}
