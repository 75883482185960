import React, { SVGAttributes } from 'react'
import clsx from 'clsx'
import ICONS from './icons'

export type IconType = keyof typeof ICONS

export function Icon({
  type,
  className = '',
  ...attrs
}: SVGAttributes<SVGElement> & {
  type: IconType
}) {
  const icon =
    ICONS[type].format === 'data' ? (
      <path d={ICONS[type].path} />
    ) : (
      ICONS[type].markup
    )

  return (
    <svg
      className={clsx('icon', className)}
      viewBox={ICONS[type].viewbox}
      width={ICONS[type]?.width}
      height={ICONS[type]?.height}
      fill={ICONS[type].fill || ''}
      {...attrs}
    >
      {icon}
    </svg>
  )
}
