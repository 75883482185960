import clsx from 'clsx'
import { DOCS_LINK, LINKS, REST_API_LINK } from 'routes/constants'
import NavLink from 'routes/components/Shared/Link/NavLink'
import { LogoBlock } from 'routes/components/Shared'
import { AdvertiseBlock } from 'routes/components/Features'
import classes from './Footer.module.css'
import iconMail from './sms.svg'

export function Footer({
  hideAdvertiseBlock = false,
}: {
  hideAdvertiseBlock?: boolean
}) {
  return (
    <>
      {!hideAdvertiseBlock && (
        <div className={clsx('container', classes.container__advertise_block)}>
          <AdvertiseBlock />
        </div>
      )}

      <footer className={classes.footer}>
        <div className={clsx('container', classes.footerContainer)}>
          <FooterTop />
          <FooterMenu />
          <FooterBottom />
        </div>
      </footer>
    </>
  )
}

function FooterTop() {
  return (
    <div className={classes.footerTop}>
      <LogoBlock className={classes.footerLogo} />
    </div>
  )
}

function FooterMenu() {
  return (
    <div className={classes.navWrapper}>
      <div>
        <h5 className={classes.groupHead}>Company</h5>
        <menu className={classes.nav}>
          <li>
            <NavLink
              href={LINKS.ARTICLES}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              Blog
            </NavLink>
          </li>
        </menu>
      </div>
      <div>
        <h5 className={classes.groupHead}>Product</h5>
        <menu className={classes.nav}>
          <li>
            <NavLink
              href={LINKS.HOW_WE_ARE_DIFFERENT}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              How we are different
            </NavLink>
          </li>
          <li>
            <NavLink
              href={LINKS.HOW_WE_USE_AI}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              How we use AI
            </NavLink>
          </li>
          <li>
            <NavLink
              href={LINKS.CONNECTOR_BUILDER}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              Connector Builder
            </NavLink>
          </li>
          <li>
            <NavLink
              href={LINKS.PRICING}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              Pricing
            </NavLink>
          </li>
        </menu>
      </div>
      <div>
        <h5 className={classes.groupHead}>Developers</h5>
        <menu className={classes.nav}>
          <li>
            <NavLink
              href={DOCS_LINK}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              Developers documentation
            </NavLink>
          </li>
          <li>
            <NavLink
              href={REST_API_LINK}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              Rest API
            </NavLink>
          </li>
        </menu>
      </div>
      <FooterConnectorsList />
    </div>
  )
}

function FooterConnectorsList() {
  const categories = [
    {
      name: 'CRM',
      amount: 20,
    },
    {
      name: 'Marketing Automation',
      amount: 12,
    },
    {
      name: 'Accounting',
      amount: 8,
    },
    {
      name: 'HR',
      amount: 1,
    },
    {
      name: 'Analytics',
      amount: 1,
    },
    {
      name: 'Sales',
      amount: 14,
    },
    {
      name: 'Communication',
      amount: 9,
    },
    {
      name: 'File Storage',
      amount: 2,
    },
    {
      name: 'Project Management',
      amount: 18,
    },
    {
      name: 'Ticketing',
      amount: 16,
    },
    {
      name: 'ERP',
      amount: 2,
    },
    {
      name: 'Customer Success',
      amount: 6,
    },
    {
      name: 'ATS',
      amount: 2,
    },
    {
      name: 'E-Commerce',
      amount: 2,
    },
  ].sort((a, b) => {
    if (a.amount === b.amount) return 0
    return a.amount < b.amount ? 1 : -1
  })

  return (
    <div className={classes.navConnectors}>
      <h5 className={classes.groupHead}>Connectors</h5>
      <ul className={classes.connectorsNav}>
        {categories.map((category) => (
          <li key={category.name}>
            <NavLink
              href={`${LINKS.CONNECTORS}?category=${category.name}`}
              className={classes.connectorsLink}
            >
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

function FooterBottom() {
  return (
    <div className={classes.footerBottom}>
      <nav className={classes.nav}>
        <li>
          <a
            href='mailto:hello@integration.app'
            className={clsx(classes.linkIcon, classes.link)}
          >
            <img src={iconMail.src} alt='Mail to' />
            hello@integration.app
          </a>
        </li>
      </nav>
      <FooterLegalLinks />
      <div>
        © {new Date().getFullYear()}{' '}
        <a href='https://integration.app' className={classes.link}>
          Integration.app
        </a>
        . All right reserved.
      </div>
    </div>
  )
}

function FooterLegalLinks() {
  return (
    <nav className={clsx(classes.nav, classes.legalLinks)}>
      <li>
        <NavLink
          href={'/privacy-policy'}
          className={classes.link}
          activeClassName={classes.link__active}
        >
          Privacy Policy
        </NavLink>
      </li>
      <li>
        <NavLink
          href={'/terms-and-conditions'}
          className={classes.link}
          activeClassName={classes.link__active}
        >
          Terms and Conditions
        </NavLink>
      </li>
    </nav>
  )
}
