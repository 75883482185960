import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import {
  DefaultPageLayout,
  DefaultPageLayoutProps,
} from '../DefaultPage/DefaultPage'
import classes from './InnerPage.module.css'

export function InnerPageLayout({
  className,
  hideAnnouncement,
  isDraft,
  children,
}: PropsWithChildren<DefaultPageLayoutProps>) {
  return (
    <DefaultPageLayout
      hideAnnouncement={hideAnnouncement}
      className={clsx(classes.bg, className)}
      isDraft={isDraft}
    >
      {children}
    </DefaultPageLayout>
  )
}
