import React from 'react'
import { getTableTitle } from 'utils'
import { TableCell } from 'routes/components/Shared'

export const TableRowDesktop = ({ data1, data2, rowKey, titles }) => {
  const data1Value = data1[rowKey]
  const data2Value = data2[rowKey]

  if (data2Value === null) return null

  return (
    <tr>
      <th>
        <TableCell value={getTableTitle(rowKey, titles)} isTitle />
      </th>

      <td>
        <TableCell value={data1Value} />
      </td>

      <td>
        <TableCell value={data2Value} />
      </td>
    </tr>
  )
}
