import { BadgesG2DataType } from './badgesG2Data'
import classes from './BadgesG2.module.scss'

export const BadgesG2Card = ({ item }: { item: BadgesG2DataType }) => {
  const { caption, badge } = item

  return (
    <li className={classes.item}>
      <img src={badge.src} alt={caption} className={classes.image} />
    </li>
  )
}
