import React, { forwardRef, Ref, InputHTMLAttributes } from 'react'
import { Icon, IconType } from 'routes/components/Shared'
import clsx from 'clsx'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string
  placeholder?: string
  icon?: IconType
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { icon, className, inputClassName, ...props },
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <div
        className={clsx('input-block', icon && 'input-block--icon', className)}
      >
        <div className='input-block__content'>
          <input
            className={clsx('input-block__element', inputClassName)}
            {...props}
            ref={ref}
          />
          {icon && <Icon type={icon} />}
        </div>
      </div>
    )
  },
)
