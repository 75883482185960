import React from 'react'
import clsx from 'clsx'
import { Link } from 'routes/components/Shared'

export const LogoBlock = ({ className }) => {
  return (
    <Link
      className={clsx('logo-block', className)}
      href='/website/public'
      target='_self'
    >
      <img
        className='logo-block__logo-image'
        src='/images/logo--white.svg'
        alt='integration.app logo'
      />

      <span className='logo-block__logo-text'>Integration.app</span>
    </Link>
  )
}
