import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import Link from 'next/link'
import classes from './ArticleBreadcrumbs.module.scss'

type ArticleBreadcrumbType = { href?: string; name: string }

export function ArticleBreadcrumbs({
  breadcrumbs = [],
  className,
  showLastArrow,
}: {
  breadcrumbs: ArticleBreadcrumbType[]
  className?: string
  showLastArrow?: boolean
}) {
  return (
    <ul
      className={clsx(
        classes.breadcrumbs,
        showLastArrow && classes.breadcrumbs__showLastArrow,
        className,
      )}
    >
      {breadcrumbs.map((breadcrumb) => (
        <ArticleBreadcrumb key={breadcrumb.name} href={breadcrumb.href}>
          {breadcrumb.name}
        </ArticleBreadcrumb>
      ))}
    </ul>
  )
}

function ArticleBreadcrumb({
  href,
  children,
}: PropsWithChildren<{ href?: string }>) {
  if (!href) return <li className={classes.breadcrumb}>{children}</li>

  return (
    <li className={classes.breadcrumb}>
      <Link href={href}>
        <a className={classes.breadcrumb_link}>{children}</a>
      </Link>
    </li>
  )
}

export function isBreadcrumb(item): item is ArticleBreadcrumbType {
  return !!item.name
}
