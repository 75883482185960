import { LINKS, DOCS_LINK } from 'routes/constants'

export const navigationArray = [
  {
    name: 'Product',
    href: null,
    type: '--left', // for adjusting submenu position on desktop
    columns: [
      {
        caption: 'Overview',
        links: [
          {
            title: 'How We Are Different',
            href: LINKS.HOW_WE_ARE_DIFFERENT,
            description: null,
          },
          {
            title: 'How We Use AI',
            href: LINKS.HOW_WE_USE_AI,
            description: null,
          },
        ],
      },
      {
        caption: 'Features',
        links: [
          {
            title: 'Integration Engine',
            href: LINKS.INTEGRATION_ENGINE,
            description: null,
          },

          {
            title: 'Connector Builder',
            href: LINKS.CONNECTOR_BUILDER,
            description: null,
          },
          {
            title: 'Integration UX',
            href: LINKS.INTEGRATION_UX,
            description: null,
          },
          {
            title: 'Universal Integrations',
            href: LINKS.UNIVERSAL_INTEGRATIONS,
            description: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Integrations',
    href: null,
    type: '--center',
    columns: [
      {
        caption: null,
        links: [
          {
            title: 'Apps',
            href: LINKS.INTEGRATIONS,
            description: null,
          },
          {
            title: 'Scenarios',
            href: LINKS.SCENARIOS,
            description: null,
          },
        ],
      },
    ],
  },
  {
    name: 'Pricing',
    href: LINKS.PRICING,
    type: '',
    columns: null,
  },
  {
    name: 'Docs',
    href: DOCS_LINK,
    type: '',
    columns: null,
  },
  {
    name: 'Company',
    href: null,
    type: '--center',
    columns: [
      {
        caption: null,
        links: [
          {
            title: 'About Us',
            href: LINKS.ABOUT_US,
            description: null,
          },
          {
            title: 'Blog',
            href: LINKS.ARTICLES,
            description: null,
          },
        ],
      },
    ],
  },
]
