import { AnchorHTMLAttributes } from 'react'
import { useTrackingContext } from 'routes/components/Layout'
import { Link, LinkType } from 'routes/components/Shared'

export default function LoginLink({
  children,
  className,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement> & {
  type?: LinkType
}) {
  const { addTrackingToUrl } = useTrackingContext()
  return (
    <Link
      href={addTrackingToUrl(`https://console.integration.app/login`)}
      {...props}
      className={className}
    >
      {children}
    </Link>
  )
}
